import React from 'react';
import { Link } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { CircularProgress, TextField, Typography, useMediaQuery, styled } from '@mui/material';
import {
  GitHub as GithubIcon,
  Reddit as RedditIcon,
  Telegram as TelegramIcon,
} from '@mui/icons-material';

import { LINKS_LIST } from 'constants/index';
import useSubscribeEmail from 'hooks/useSubscribeEmail';

import { ReactComponent as DiscordIconOriginal } from 'assets/images/discord.svg';
import { ReactComponent as GMILogo } from 'assets/images/GMILogo.svg';
import { LinkedinIcon, TwitterXIcon } from 'components/ImageComponent';
import { GeneralFlexBox } from 'components/StyledComponents';
import { StandardButton } from 'components/Button';
import { Text, GreenLinkText } from 'components/Text';

/********************  Styled Components  ********************/
const EmailContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 24,
  margin: '25px 0',
  width: '100%',

  '@media(max-width: 720px)': {
    gap: 16,
  },
}));

const MailingListInput = styled(TextField)(({ theme }) => ({
  '& input': {
    color: theme.palette.text.title,
    height: 45,
    padding: '0 15px',
    width: '100%',
    color: theme.palette.text.main,
    fontSize: '15px',
    backgroundColor: theme.palette.background.default,
    borderRadius: 6,
    border: `1px solid ${theme.palette.border.decorative}`,

    '&::placeholder': {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      opacity: 1,
    },
    '&:hover': {
      background: theme.palette.background.offer.hovered,
    },
    '&:focus': {
      backgroundColor: theme.palette.background.default,
    },
  },

  '& fieldset': {
    border: 'none',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 6,
  },

  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.danger.variant}`,
  },

  width: 298,

  '@media(max-width: 1280px)': {
    width: 411,
  },
  '@media(max-width: 600px)': {
    width: 212,
  },
  '@media(max-width: 590px)': {
    width: '100%',
  },
  '@media(max-width: 360px)': {
    width: 201,
  },
}));

const MainHead = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.text.secondary,
  opacity: 0.6,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  margin: 0,

  '@media(max-width: 600px)': {
    width: 330,
  },
}));

const Main = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.footer.text,
  margin: '5px 0',
  lineHeight: '20px',
  letterSpacing: '0.25px',

  '@media(max-width: 720px)': {
    textAlign: 'center',
  },
}));

const LinkCustom = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.footer.text,
  margin: '5px 0',
  lineHeight: '20px',
  letterSpacing: '0.25px',

  ':hover': {
    textDecorationColor: 'black',
    cursor: 'pointer',
    color: theme.palette.text.white,
  },
}));

const LinkLinkTo = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.footer.text,
  margin: '5px 0',
  lineHeight: '20px',
  letterSpacing: '0.25px',

  ':hover': {
    textDecorationColor: 'black',
    cursor: 'pointer',
    color: theme.palette.text.white,
  },
}));

const CommunityListDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 24,

  '& a': {
    color: theme.palette.text.secondary,
    transition: '0.5s',
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
  },

  '@media(max-width: 1200px)': {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

const DiscordIcon = styled((props) => <DiscordIconOriginal {...props} />)(({ theme }) => ({
  fill: theme.palette.text.secondary,
  width: 20,

  '&:hover': {
    fill: theme.palette.primary.main,
  },
}));

export const Divider = styled('div')(({ theme }) => ({
  height: '1px',
  width: '100%',
  backgroundColor: theme.palette.border.separator,
  margin: '0px',
}));

const BelowFooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.placeholder,
  fontSize: 14,
  lineHeight: '20px',
}));

const NewsletterWrapper = styled(GeneralFlexBox)(() => ({
  alignItems: 'start',
  flexDirection: 'column',
  width: '433px',

  '@media(max-width: 1280px)': {
    width: '546px',
  },
  '@media(max-width: 1170px)': {
    marginLeft: '-130px',
  },
  '@media(max-width: 890px)': {
    width: '339px',
    marginLeft: '-100px',
  },
  '@media(max-width: 590px)': {
    marginLeft: 0,
  },
}));

const FooterLinkWrapper = styled(GeneralFlexBox)(() => ({
  flexDirection: 'column',
  alignItems: 'start',
  margin: '18px 0 0 -40px',

  '@media(max-width: 590px)': {
    margin: '18px 0 25px 0px',
    alignItems: 'center',
  },
}));

/********************  Sub Components  ********************/

const FooterLinkList = ({ list, include }) => {
  return (
    <GeneralFlexBox direction="column">
      <GMILogo width="195" height="42.5" />
      <FooterLinkWrapper>
        {include?.map((item) => {
          const isExternal = list[item]?.external;
          return (
            <Main key={list[item]?.name}>
              {isExternal ? (
                <LinkCustom href={list[item]?.link} target="_blank" rel="noopener noreferrer">
                  {list[item]?.name}
                </LinkCustom>
              ) : (
                <LinkLinkTo to={list[item]?.link}>{list[item]?.name}</LinkLinkTo>
              )}
            </Main>
          );
        })}
      </FooterLinkWrapper>
    </GeneralFlexBox>
  );
};

const CommunityList = ({ socialMediaLinks }) => {
  return (
    <>
      <Text sx={{ color: (theme) => theme.palette.text.white }} fs={16} fw={600} lh={24} mb={16}>
        Join GMI Community
      </Text>
      <CommunityListDiv>
        {socialMediaLinks?.map((media) => {
          return (
            <a key={media.name} rel="noopener noreferrer" target="_blank" href={media.link}>
              {media.icon}
            </a>
          );
        })}
      </CommunityListDiv>
    </>
  );
};

const NewsletterForm = ({ isLoading, signupEmail, handleEmailChange, handleSignup }) => {
  return (
    <>
      <GeneralFlexBox direction="column" align="start">
        <Text
          fs={20}
          fw={600}
          lh={28}
          mb={16}
          lg={{ fs: 16, lh: 24 }}
          sx={{ color: (theme) => theme.palette.text.white }}
        >
          Stay in the loop
        </Text>
        <MainHead>
          Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and
          tips and tricks for navigating GonnaMakeIt.
        </MainHead>
      </GeneralFlexBox>
      <EmailContainer onSubmit={handleSignup}>
        <MailingListInput
          id="signup-email"
          placeholder="Your email address"
          type="email"
          value={signupEmail}
          onChange={handleEmailChange}
          autoComplete="off"
        />
        <div>
          <StandardButton
            variant="contained"
            color="primary"
            width="111px"
            height="45px"
            disableRipple
            type="submit"
          >
            {isLoading ? <CircularProgress sx={{ p: 1, color: 'white' }} /> : 'Sign Up'}
          </StandardButton>
        </div>
      </EmailContainer>
    </>
  );
};

/********************  Main Component  ********************/
const Footer = () => {
  const below600 = useMediaQuery('(max-width: 590px)');

  const [signupEmail, setSignupEmail] = useStateIfMounted('');

  const { subscribeEmail, isLoading } = useSubscribeEmail();

  const footerLinkKeys = [
    'aboutUs',
    'docs',
    'contactUs',
    'guides',
    'faq',
    'terms',
    'privacyPolicy',
  ];

  const socialMediaLinks = [
    { ...LINKS_LIST['twitter'], icon: <TwitterXIcon /> },
    { ...LINKS_LIST['discord'], icon: <DiscordIcon /> },
    { ...LINKS_LIST['telegram'], icon: <TelegramIcon /> },
    { ...LINKS_LIST['reddit'], icon: <RedditIcon /> },
    { ...LINKS_LIST['github'], icon: <GithubIcon /> },
    { ...LINKS_LIST['linkedin'], icon: <LinkedinIcon /> },
  ];

  const handleEmailChange = (event) => {
    setSignupEmail(event.target.value);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    subscribeEmail(signupEmail);
  };

  return (
    <div style={{ width: '100%', background: 'black' }}>
      <Divider />
      {below600 ? (
        <>
          <GeneralFlexBox
            direction="column"
            justify="center"
            align="center"
            padding="48px 12px 32px 12px"
          >
            <NewsletterForm
              isLoading={isLoading}
              signupEmail={signupEmail}
              handleEmailChange={handleEmailChange}
              handleSignup={handleSignup}
            />
            <FooterLinkList list={LINKS_LIST} include={footerLinkKeys} />
            <CommunityList socialMediaLinks={socialMediaLinks} />
          </GeneralFlexBox>
        </>
      ) : (
        <GeneralFlexBox width="100%" height="333px" justify="space-between">
          <GeneralFlexBox width="50%">
            <FooterLinkList list={LINKS_LIST} include={footerLinkKeys} />
          </GeneralFlexBox>
          <GeneralFlexBox width="50%" justify="center">
            <NewsletterWrapper>
              <NewsletterForm
                isLoading={isLoading}
                signupEmail={signupEmail}
                handleEmailChange={handleEmailChange}
                handleSignup={handleSignup}
              />
              <CommunityList socialMediaLinks={socialMediaLinks} />
            </NewsletterWrapper>
          </GeneralFlexBox>
        </GeneralFlexBox>
      )}
      <Divider />
      <GeneralFlexBox
        direction={below600 ? 'column-reverse' : 'row'}
        justify="space-between"
        width="100%"
        padding="24px 32px"
        sx={{ gap: 2 }}
      >
        <BelowFooterText>
          © {new Date().getFullYear()} Gonnamakeit. All rights reserved.{' '}
        </BelowFooterText>
        <BelowFooterText textAlign={'center'}>
          The charting solution is provided by TradingView to help track coins like{' '}
          <GreenLinkText href="https://www.tradingview.com/symbols/BTCUSD/" fs={12} fw={400}>
            BTC USD
          </GreenLinkText>{' '}
          on charts and more.
        </BelowFooterText>
      </GeneralFlexBox>
    </div>
  );
};

export default Footer;
