/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { ReactComponent as MetamaskTitleIcon } from 'assets/images/metamask-fox-title.svg';
import useWalletContext from 'hooks/useWalletContext';
import { METAMASK_DOWNLOAD_URL, METAMASK_GUIDE_URL } from 'constants/index';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  textAlign: 'center',

  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.background.overlay,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0, 8),
    backgroundColor: theme.palette.background.secondary,
  },
  '& .MuiDialogActions-root': {
    backgroundColor: theme.palette.background.secondary,
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
  },

  '@media (max-width: 550px)': {
    '& .MuiDialog-paper': {
      position: 'fixed',
      bottom: 0,
      margin: 0,
    },
  },
}));

const ModalDialogTitle = styled(DialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  color: theme.palette.text.main,
  fontWeight: 600,
  fontSize: 20,
  backgroundColor: theme.palette.background.secondary,
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  opacity: '1',
  padding: '0.5rem',
  paddingTop: '1rem',
  textAlign: 'center',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.2px',
  backgroundColor: theme.palette.background.secondary,

  '& a': {
    color: theme.palette.button.active,
    textDecoration: 'none',
  },
  '& a:hover': {
    textDecoration: 'underline',
  },
}));

const Row = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const MetamaskTitle = styled(MetamaskTitleIcon)(({ theme }) => ({
  width: 183,
  marginBottom: '1rem',

  '& .logo-theme': {
    fill: theme.palette.text.main,
  },
}));

const SeparatorLine = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.decorative}`,
  margin: '0 1rem',
}));

const FooterText = styled('p')(() => ({
  fontWeight: 400,
  '& a': {
    marginLeft: 4,
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <ModalDialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            padding: '14px',
            color: (theme) => theme.palette.text.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </ModalDialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MetamaskNotInstalledModal({ callback = () => {} }) {
  const theme = useTheme();
  const { walletModalOpen, setWalletModalOpen } = useWalletContext();

  const onClose = () => {
    setWalletModalOpen(false);
    callback && callback();
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={walletModalOpen}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} />
      <ContentWrapper>
        <Row>
          <MetamaskTitle />
        </Row>
        <p style={{ color: theme.palette.text.secondary }}>
          Metamask not installed. Please visit the {` `}
          <a href={METAMASK_GUIDE_URL} target="_blank" rel="noopener noreferrer">
            docs
          </a>
          {` `}
          for guidance in installing and creating a Metamask wallet.
        </p>
        <SeparatorLine />
        <FooterText>
          Don't have Metamask?
          <a href={METAMASK_DOWNLOAD_URL} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </FooterText>
      </ContentWrapper>
    </BootstrapDialog>
  );
}
