import { sepolia, mainnet } from 'viem/chains';
import { createConfig, http } from 'wagmi';
import { coinbaseWallet, injected } from 'wagmi/connectors';

import { ENERGI_MAINNET, ENERGI_TESTNET } from 'constants/chains';

const wagmiConfig = createConfig({
  chains: [mainnet, sepolia, ENERGI_MAINNET, ENERGI_TESTNET],
  connectors: [
    injected(),
    coinbaseWallet({
      appName: 'GonnaMakeIt NFT Marketplace',
    }),
  ],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http('https://eth-sepolia.public.blastapi.io'),
    [ENERGI_MAINNET.id]: http(),
    [ENERGI_TESTNET.id]: http(),
  },
});

export default wagmiConfig;
