import React from 'react';
import { RadioGroup, Radio, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { LISTING_DURATIONS } from 'constants/index';

import {
  StyledBackdrop,
  StyledForm,
  StyledLabel,
  StyledControlLabel,
  GeneralFlexBox,
} from 'components/StyledComponents';
import { ModalIconButton } from '../../components/styledComponents';

/********************  Main Component  ********************/
const DayMobileView = ({ expiredDate, setExpiredDate, open, setOpen }) => {
  const theme = useTheme();

  const handleExpiredDateChange = (event) => {
    setExpiredDate(Number(event.target.value));
    setOpen(false);
  };

  return (
    <StyledBackdrop open={open}>
      <StyledForm>
        <GeneralFlexBox width="100%" justify="space-between" sx={{ mb: '14px' }}>
          <StyledLabel id="demo-radio-buttons-group-label">Offer expiration</StyledLabel>
          <ModalIconButton onClick={() => setOpen(false)}>
            <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
          </ModalIconButton>
        </GeneralFlexBox>

        <RadioGroup value={expiredDate || ''} onChange={handleExpiredDateChange} sx={{ mb: 1 }}>
          {LISTING_DURATIONS.map((duration) => (
            <StyledControlLabel
              key={duration.value}
              value={duration.value}
              control={<Radio />}
              label={duration.key}
            />
          ))}
        </RadioGroup>
      </StyledForm>
    </StyledBackdrop>
  );
};

export default DayMobileView;
