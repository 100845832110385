import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import userService from 'services/user';

import { SOMETHING_WENT_WRONG } from 'constants/index';
import useWalletContext from 'hooks/useWalletContext';
import useNotification from 'hooks/useNotification';
import { addTimestampToImageUrl, correctNullToEmptyString } from 'utils/helper';
import {
  EMAIL_ADDRESS_VALIDATOR,
  USERNAME_VALIDATOR,
  BIO_STRING_VALIDATOR,
  URL_STRING_VALIDATOR,
} from 'utils/validators';

export const UserContext = createContext(null);
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useStateIfMounted({});
  const [showEmailModal, setShowEmailModal] = useStateIfMounted(false);
  const [isLoading, setIsLoading] = useStateIfMounted(false);

  const { address } = useWalletContext();
  const showNotification = useNotification();

  useEffect(() => {
    if (address) {
      getUserData();
    }
  }, [address]);

  const getUserData = useCallback(async () => {
    const userData = await userService.getUser(address);

    if (!userData) {
      return;
    }
    formatUserData(userData.data);
  }, [address]);

  const formatUserData = (data) => {
    if (data) {
      const formattedUserData = correctNullToEmptyString(data);
      const { avatar, banner, ...rest } = formattedUserData;
      setUserData({
        avatar: avatar ? addTimestampToImageUrl(formattedUserData?.avatar) : '',
        banner: banner ? addTimestampToImageUrl(formattedUserData?.banner) : '',
        ...rest,
      });
    }
  };

  const updateUserData = useCallback(
    async ({ data, avatar, banner }) => {
      if (!data.email) {
        return showNotification({ type: 'error', message: 'Email is required' });
      }

      setIsLoading(true);
      const { username, bio, email, twitter, instagram, website } = data;
      const images = {};

      avatar !== userData.avatar ? (images.newAvatar = avatar) : (images.avatar = avatar);
      banner !== userData.banner ? (images.newBanner = banner) : (images.banner = banner);

      const newUserData = {
        ...{ address, username, bio, email, twitter, instagram, website },
        ...images,
      };

      try {
        if (!userData.verifiedEmail || userData.verifiedEmail !== data.email) {
          return setShowEmailModal(true);
        }
        const responseData = await userService.updateUser(newUserData);
        formatUserData(responseData.data);

        if (responseData?.success) {
          getUserData();
          return showNotification({
            message: 'Your profile successfully updated!',
            type: 'success',
          });
        }
        if (responseData.errorMessages) {
          return showNotification({
            message: responseData.errorMessages[0],
            type: 'error',
          });
        }
        if (responseData.errors) {
          return responseData.errors.forEach((error) => {
            showNotification({
              message: error.message,
              type: 'error',
            });
          });
        }
      } catch (e) {
        showNotification({
          message: SOMETHING_WENT_WRONG,
          type: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [userData],
  );

  const formData = useMemo(
    () => ({
      username: { error: false, value: userData.username, validator: USERNAME_VALIDATOR },
      bio: { error: false, value: userData.bio, validator: BIO_STRING_VALIDATOR },
      email: { error: false, value: userData.verifiedEmail, validator: EMAIL_ADDRESS_VALIDATOR },
      twitter: { error: false, value: userData.twitter, validator: URL_STRING_VALIDATOR },
      instagram: { error: false, value: userData.instagram, validator: URL_STRING_VALIDATOR },
      website: { error: false, value: userData.website, validator: URL_STRING_VALIDATOR },
    }),
    [userData],
  );

  return (
    <UserContext.Provider
      value={{
        userData,
        formData,
        updateUserData,
        showEmailModal,
        setShowEmailModal,
        closeEmailModal: () => setShowEmailModal(false),
        isLoading,
        displayName: userData?.username,
        profilePicture: userData?.avatar,
        profileBanner: userData?.banner,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
