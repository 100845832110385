import { useCallback, useMemo } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import notificationService from 'services/notification';

import { SOMETHING_WENT_WRONG } from 'constants/index';
import useNotification from './useNotification';

const useNotificationSettings = () => {
  const [userSettings /*setSettings*/] = useStateIfMounted({});
  const [isLoading, setIsLoading] = useStateIfMounted(false);

  const showNotification = useNotification();

  /*
  TODO: Not working properly, needs to be fixed in the future
  useEffect(() => {
    getUserSettings();
  }, []);
  */

  /*
  const getUserSettings = useCallback(async () => {
    const userSettings = await notificationService.getUserSettings();
    setSettings(userSettings);
  }, []);
  */

  const updateUserSettings = useCallback(async (data) => {
    setIsLoading(true);
    try {
      await notificationService.updateUserSettings({
        item_sold: !!data?.item_sold,
        bid_activity: !!data?.bid_activity,
        price_change: !!data?.price_change,
        auction_expiraion: !!data?.auction_expiraion,
        outbid: !!data?.outbid,
        owned_item_auction: !!data?.owned_item_auction,
        successful_purchase: !!data?.successful_purchase,
        energi_newsletter: !!data?.energi_newsletter,
        referral_successful: !!data?.referral_successful,
        min_bid_threshold: data?.minimum_bid_threshold || '0',
      });
      showNotification({
        message: 'Your notification settings were successfully updated!',
        type: 'success',
      });
    } catch (error) {
      showNotification({
        message: SOMETHING_WENT_WRONG,
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const formData = useMemo(
    () => ({
      inputs: [
        {
          name: 'item_sold',
          title: 'Item Sold',
          description: 'When someone purchased one of your items',
          checked: userSettings?.item_sold,
        },
        {
          name: 'bid_activity',
          title: 'Bid Activity',
          description: 'When someone bids on one of your items',
          checked: userSettings?.bid_activity,
        },
        {
          name: 'price_change',
          title: 'Price Change',
          description: 'When an item you made an offer on changes in price',
          checked: userSettings?.price_change,
        },
        {
          name: 'auction_expiraion',
          title: 'Auction Expiration',
          description: 'When a Dutch or English auction you created ends',
          checked: userSettings?.auction_expiraion,
        },
        {
          name: 'outbid',
          title: 'Outbid',
          description: 'When an offer you placed is exceeded by another user',
          checked: userSettings?.outbid,
        },
        {
          name: 'referral_successful',
          title: 'Referral Successful',
          description: 'When an item you referred is purchased',
          checked: userSettings?.referral_successful,
        },
        {
          name: 'owned_item_auction',
          title: 'Owned Asset Updates',
          description:
            'When a significant update occurs for one of the items you have purchased on GonnaMakeIt',
          checked: userSettings?.owned_item_auction,
        },
        {
          name: 'successful_purchase',
          title: 'Successful Purchase',
          description: 'When you successfully buy an item',
          checked: userSettings?.successful_purchase,
        },
        {
          name: 'energi_newsletter',
          title: 'GonnaMakeIt Newsletter',
          description: 'Occasional updates from the GonnaMakeIt team',
          checked: userSettings?.energi_newsletter,
        },
      ],
      minimum_bid_threshold: Number(userSettings?.min_bid_threshold) || '',
    }),
    [userSettings],
  );

  return { formData, updateUserSettings, isLoading };
};

export default useNotificationSettings;
