import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { readContract } from '@wagmi/core';
import { getContract } from 'viem';

import wagmiConfig from 'wagmiConfig';
import { TOTAL_ROYALTY_PERCENTAGE } from 'constants/index';
import contracts from 'constants/contracts';
import useWalletContext from './useWalletContext';
import { calculateGas } from 'utils';

const useRoyalties = ({ address }) => {
  const { storedChain, signer } = useWalletContext();

  const [currentEarnings, setCurrentEarnings] = useStateIfMounted([]);
  const [isLoading, setIsLoading] = useStateIfMounted(false);
  const [isSaving, setIsSaving] = useStateIfMounted(false);

  const { RoyaltyRegistryStorage, RoyaltyRegistry } = contracts[storedChain];

  const fetchRoyalties = async () => {
    setIsLoading(true);
    try {
      const data = await readContract(wagmiConfig, {
        abi: RoyaltyRegistryStorage.ABI,
        address: RoyaltyRegistryStorage.address,
        functionName: 'getRoyaltiesByToken',
        args: [address],
        chainId: storedChain,
      });

      if (data.royalties) {
        const earnings = data.royalties.map((item) => ({
          earnerAddress: item.account,
          percentage: item.value / TOTAL_ROYALTY_PERCENTAGE.MULTIPLIER,
        }));

        setCurrentEarnings(earnings);
      }
    } catch (error) {
      console.error('Error fetching royalties:', error);
    }

    setIsLoading(false);
  };

  const save = async (earnings) => {
    try {
      setIsSaving(true);
      const royalties = earnings.map((item) => [
        item.earnerAddress,
        item.percentage * TOTAL_ROYALTY_PERCENTAGE.MULTIPLIER,
      ]);

      const royaltiesContract = getContract({
        address: RoyaltyRegistry.address,
        abi: RoyaltyRegistry.ABI,
        client: { wallet: signer },
      });
      const gasEstimate = await royaltiesContract.estimateGas.setRoyaltiesByToken([
        address,
        [...royalties],
      ]);
      const hash = await royaltiesContract.write.setRoyaltiesByToken([address, [...royalties]], {
        gas: calculateGas(gasEstimate),
      });

      if (hash) {
        return hash;
      }
    } catch (error) {
      throw error;
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (storedChain) {
      fetchRoyalties();
    }
  }, [storedChain]);

  return { currentEarnings, setCurrentEarnings, fetchRoyalties, save, isLoading, isSaving };
};

export default useRoyalties;
