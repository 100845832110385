import React, { useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import { useStateIfMounted } from 'use-state-if-mounted';
import { differenceInDays, isBefore, isToday } from 'date-fns';
import { Typography, Popover, useMediaQuery, styled, useTheme } from '@mui/material';
import {
  CalendarToday as CalendarTodayIcon,
  Close as CloseIcon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';

import { LISTING_DURATIONS } from 'constants/index';

import 'react-day-picker/dist/style.css';
import './durationModal.css';

import {
  GeneralFlexBox,
  StyledBackdrop,
  StyledForm,
  StyledLabel,
  StyledTimepicker,
} from 'components/StyledComponents';
import { ModalIconButton } from '../components/styledComponents';

/********************  Styled Components  ********************/
const DurationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
  gap: '10px',
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: 4,

  '@media(max-width: 500px)': {
    border: 'none',
    paddingTop: 10,
  },
}));

const DurationMainDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  height: '48px',
  padding: '13px 18px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.border.decorative}`,

  '&:hover': {
    cursor: 'pointer',
  },
}));

const TimeSelectDiv = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: 4,
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center',
  height: 48,
  padding: 10,

  '@media(max-width: 600px)': {
    marginBottom: 14,
  },
}));

const StyledDaypicker = styled(DayPicker)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: 4,
  width: '100%',
  padding: theme.spacing(1),

  '&.rdp': {
    margin: '0px !important',
  },
  '& .rdp-caption': {
    marginBottom: 12,
  },
  '& .rdp-day_selected': {
    backgroundColor: `${theme.palette.button.primary} !important`,
  },
  '& .rdp-nav_button:hover': {
    backgroundColor: `${theme.palette.button.primary} !important`,
  },
  '& .rdp-day:hover': {
    backgroundColor: `${theme.palette.button.primary} !important`,
  },
  '& .rdp-months': {
    '& .rdp-table': {
      borderCollapse: 'inherit',
    },
  },
}));

const currentDate = new Date();

const formatWeekdayName = (weekName) => {
  return weekName.toString().substr(0, 1);
};

/********************  Main Component  ********************/
const DateTimePicker = ({ expiredDate, dateWithExactTime, setExpiredDate, setExactTime }) => {
  const theme = useTheme();
  const below500 = useMediaQuery('(max-width: 500px)');

  const [anchor, setAnchor] = useStateIfMounted(null);
  const [startTime, setStartTime] = useStateIfMounted(currentDate);
  const [boxOpen, setBoxOpen] = useStateIfMounted(false);

  const open = Boolean(anchor);
  const id = open ? 'duration-popover' : undefined;

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
    setBoxOpen(true);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleDateChange = (event) => {
    const selectedDate = new Date(event);
    if (!event || isToday(selectedDate) || isBefore(selectedDate, currentDate)) {
      return;
    }
    const daysAhead = differenceInDays(selectedDate, currentDate);
    setExpiredDate((daysAhead + 1) * 24);
  };

  const handleTimeChange = (newValue) => {
    if (!newValue) {
      return;
    }

    const newTime = moment(newValue, 'HH:mm');
    const currentTimeMoment = moment(currentDate, 'HH:mm');
    const timeDifference = newTime.diff(currentTimeMoment, 'hours', true);

    if (timeDifference > 1 && timeDifference < 24) {
      setExpiredDate(timeDifference);
    }

    setStartTime(newValue);
    const [hours, minutes] = newValue.split(':').map(Number);
    setExactTime({ hours, minutes });
  };

  // Check if expiredDate is equal to 1 hour
  useEffect(() => {
    if (expiredDate === LISTING_DURATIONS[0].value) {
      const newTime = moment(currentDate).add(1, 'hour').toDate();
      setStartTime(moment(newTime).format('HH:mm'));
      setExactTime({ hours: newTime.getHours(), minutes: newTime.getMinutes() });
    }
  }, [expiredDate]);

  const layout = () => {
    return (
      <DurationContainer>
        <StyledDaypicker
          mode="single"
          formatters={{ formatWeekdayName }}
          selected={dateWithExactTime}
          onSelect={handleDateChange}
          defaultMonth={dateWithExactTime}
        />

        <TimeSelectDiv>
          <AccessTimeIcon sx={{ color: theme.palette.icon.clock }} />
          <StyledTimepicker
            isOpen={false}
            openClockOnFocus={false}
            onChange={handleTimeChange}
            value={startTime}
            format={'hh:mm a'}
            disableClock={true}
            closeClock={false}
            clearIcon={null}
          />
        </TimeSelectDiv>
      </DurationContainer>
    );
  };

  const desktopView = () => {
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ zIndex: 3000 }}
      >
        {layout()}
      </Popover>
    );
  };

  const mobileView = () => {
    return (
      <StyledBackdrop open={boxOpen}>
        <StyledForm>
          <GeneralFlexBox width="100%" justify="space-between" sx={{ mb: '14px' }}>
            <StyledLabel id="demo-radio-buttons-group-label">Choose date & time</StyledLabel>
            <ModalIconButton onClick={() => setBoxOpen(false)}>
              <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
            </ModalIconButton>
          </GeneralFlexBox>
          {layout()}
        </StyledForm>
      </StyledBackdrop>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DurationMainDiv onClick={(event) => handleClick(event)}>
        {!below500 && (
          <CalendarTodayIcon
            sx={{
              color: theme.palette.text.secondary,
              marginRight: 2,
            }}
          />
        )}
        <Typography fontWeight={500} fontSize={!below500 ? 14 : 12}>
          {`${moment(dateWithExactTime).format('MMM DD, YYYY')} ${moment(dateWithExactTime).format(
            'hh:mm',
          )}`}
        </Typography>
        <Typography
          color={theme.palette.text.secondary}
          fontWeight={400}
          fontSize={12}
          marginTop={below500 ? 0 : 0.5}
        >
          {`${moment(dateWithExactTime).format('A')}`}
        </Typography>
      </DurationMainDiv>
      {!below500 ? desktopView() : mobileView()}
    </LocalizationProvider>
  );
};

export default DateTimePicker;
