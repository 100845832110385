import React from 'react';

import { PanelExpand, PanelExpandContent } from 'components/StyledPanelExpand';
import { DescriptionContent, DescriptionText } from './common';

const OfferDetails = ({ wrappedCoin }) => {
  return (
    <PanelExpand radius="8px">
      <PanelExpandContent in={true} timeout="auto" unmountOnExit style={{ borderRadius: '8px' }}>
        <DescriptionContent>
          <DescriptionText>
            If a seller accepts your offer, it’ll be automatically withdrawn from all other items
            and your purchase will be finalized.
          </DescriptionText>
          <br />
          <DescriptionText>
            Keep enough {wrappedCoin} in your wallet to pay, or the seller won’t be able to accept
            your offer.
          </DescriptionText>
        </DescriptionContent>
      </PanelExpandContent>
    </PanelExpand>
  );
};

export default OfferDetails;
