import React, { createContext, useContext, useEffect, useState } from 'react';

import general from '../services/general';

import { NETWORK } from 'constants/index';
import { useReserveValues } from 'hooks/farmingFunction';
import useWalletContext from 'hooks/useWalletContext';

const PriceContext = createContext();
export const usePriceContext = () => useContext(PriceContext);

export const PriceProvider = ({ children }) => {
  const { storedChain } = useWalletContext();
  const [ethPrice, setEthPrice] = useState();
  const [nrgPrice, setNrgPrice] = useState();
  const [coinPrice, setCoinPrice] = useState(0);
  const [ethPerGmi] = useReserveValues();

  useEffect(() => {
    const fetchPrice = async () => {
      const symbol = NETWORK[storedChain].symbol;
      const prices = await general.prices();

      if (prices) {
        switch (symbol) {
          case 'ETH':
            setEthPrice(prices.ethereum);
            setCoinPrice(prices.ethereum);
            break;
          case 'NRG':
          default:
            setNrgPrice(prices.energi);
            setCoinPrice(prices.energi);
            break;
        }
      }
    };

    fetchPrice();
  }, [storedChain]);

  return (
    <PriceContext.Provider
      value={{ ethPrice, gmiPrice: ethPrice * ethPerGmi, nrgPrice, coinPrice }}
    >
      {children}
    </PriceContext.Provider>
  );
};
