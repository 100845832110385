import React from 'react';
import { Button, styled, Typography } from '@mui/material';

import { NETWORK_NAME } from 'constants/index';
import useWalletContext from 'hooks/useWalletContext';

import { WarningIcon } from 'components/ImageComponent';

/********************  Styled Components  ********************/
const WarningContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 1),
  alignItems: 'center',
  backgroundColor: theme.palette.header,
  justifyContent: 'center',
  textAlign: 'center',
  height: 60,
  width: '100%',
  zIndex: 1300,
  position: 'fixed',
  top: 0,

  boxShadow:
    '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',

  '& > div, & > a': {
    padding: theme.spacing(0, 1.6),
  },
}));

const SwitchButton = styled(Button)(({ theme }) => ({
  background:
    theme.palette.mode === 'dark' ? theme.palette.timer : theme.palette.background.default,
  padding: theme.spacing(0.5, 2),
  borderRadius: 4,
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: '0.25px',
  lineHeight: '20px',
  color: theme.palette.text.main,

  '&:hover': {
    background: theme.palette.timer,
    opacity: 0.9,
  },
}));

const WarningText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  margin: '0px 16px',
  color: theme.palette.text.white,
}));

/********************  Main Component  ********************/
const WrongNetworkWarning = () => {
  const { wrongNetwork, switchToNetwork, storedChain } = useWalletContext();

  if (!wrongNetwork) {
    return null;
  }

  return (
    <WarningContainer>
      <WarningIcon />
      <WarningText>Please switch to {NETWORK_NAME[storedChain]}</WarningText>
      <SwitchButton onClick={async () => await switchToNetwork(storedChain)} disableRipple>
        Switch
      </SwitchButton>
    </WarningContainer>
  );
};

export default WrongNetworkWarning;
