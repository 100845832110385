import { useCallback } from 'react';
import { parseUnits } from 'viem';

import orderbookService from 'services/orderbook';

import { SOMETHING_WENT_WRONG } from 'constants/index';
import useWalletContext from './useWalletContext';
import { useSignOrder, useCheckAndUpdateOrderIfFilled } from 'utils/onchain/exchange';
import { constructMakeOfferMessage, constructOfferOrder } from 'utils/orderbook/offer';

/********************  Main Component  ********************/
const useCreateMakeOfferOrder = ({ value, owner, contractAddress, tokenId, timeRange }) => {
  const { address } = useWalletContext();
  const checkAndUpdateOrderIfFilled = useCheckAndUpdateOrderIfFilled();
  const signOrder = useSignOrder();

  const valueWeiBN = parseUnits(value || '0', 18);

  const { message } = constructMakeOfferMessage({
    address,
    owner,
    contractAddress,
    tokenId,
    valueWeiBN,
    start: timeRange.start,
    end: timeRange.end,
  });

  const createOBOrder = useCallback(async () => {
    // check if order is already filled or not
    const checkedOrder = await checkAndUpdateOrderIfFilled(message);

    if (!checkedOrder) {
      throw Error(SOMETHING_WENT_WRONG);
    }

    const signResponse = await signOrder(checkedOrder);

    if (!signResponse) {
      throw Error('Signature rejected');
    }

    const order = constructOfferOrder({
      address,
      owner,
      contractAddress,
      tokenId,
      valueWeiBN,
      signedOrder: signResponse,
      salt: checkedOrder.salt,
      start: timeRange.start,
      end: timeRange.end,
    });

    const res = await orderbookService.create(order);

    if (!res?.Key) {
      throw Error(SOMETHING_WENT_WRONG);
    }
  }, [
    value,
    checkAndUpdateOrderIfFilled,
    message,
    signOrder,
    owner,
    contractAddress,
    tokenId,
    timeRange.start,
    timeRange.end,
  ]);

  return createOBOrder;
};

export default useCreateMakeOfferOrder;
