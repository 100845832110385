import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Typography, styled, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { COIN } from 'constants/index';
import useRoyalties from 'hooks/useRoyalties';
import useTokenContext from 'hooks/useTokenContext';
import useWalletContext from 'hooks/useWalletContext';
import { getFullMediaUrl } from 'utils/image';
import { formatNumber } from 'utils/numbers';
import { getListingPrice } from 'utils/order';

import {
  GeneralFlexBox,
  Price,
  StyledInfoOutlinedIcon,
  ToolTip,
} from 'components/StyledComponents';
import { StandardButton } from 'components/Button';
import {
  CheckoutModalRow,
  ModalHeaderContainer,
  ModalIconButton,
} from '../components/styledComponents';
import ConnectWallet from '../components/ConnectWallet';

const MainContainer = styled(GeneralFlexBox)(({ visible }) => ({
  width: '100%',
  display: visible ? 'flex' : 'none',
  flexDirection: 'column',
  // Move footer row to bottom screen when in vertical small screen
  '@media(max-width: 600px)': {
    height: '100%',
    justifyContent: 'space-between',
  },
}));

export const SimpleDivider = styled('div')(({ color }) => ({
  height: '1px',
  width: '100%',
  backgroundColor: color ?? 'rgba(30, 68, 94, 1)',
  margin: '0px',
}));

const CheckoutFooterRow = styled(CheckoutModalRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.separator}`,
}));

// Thumbnail is the same in every screen size
const CheckoutThumbnail = styled('img')(({ theme }) => ({
  width: 72,
  height: 72,
  borderRadius: 5,
  border: `2px solid ${theme.palette.border.decorative}`,
}));
const AssetNameText = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 600,
}));

const DescriptonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  lineHeight: '16px',
  fontSize: '12px',
  fontWeight: 500,
  paddingTop: '4px',
}));

const FeeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  lineHeight: '16px',
  fontSize: '12px',
  fontWeight: 500,
  paddingTop: '4px',
  display: 'inline-flex',
  alignItems: 'center',
}));

const TotalRow = styled('div')(({ theme, direction = 'row' }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: direction ?? 'row',
  width: '100%',
}));

/********************  Main Component  ********************/
const CheckoutPart = ({
  data,
  closeModal,
  onConfirmPayment,
  onAddFunds,
  nativeBalance,
  path,
  visible,
}) => {
  const theme = useTheme();

  const { isConnected } = useWalletContext();
  const { isAuthenticated } = useTokenContext();
  const { currentEarnings } = useRoyalties({ address: data.contractAddress });

  const [enoughFund, setEnoughFund] = useStateIfMounted(true);

  const creatorFee = currentEarnings.reduce((sum, item) => sum + item.percentage, 0);
  const isConnectedAndAuthenticated = isConnected && isAuthenticated;
  const listingPrice = getListingPrice(data);

  useEffect(() => {
    const price = parseFloat(listingPrice);

    if (price > nativeBalance) {
      setEnoughFund(false);
    } else {
      setEnoughFund(true);
    }
  }, [nativeBalance]);

  return (
    <MainContainer direction="column" width="100%" visible={visible ? 'true' : 'false'}>
      <ModalHeaderContainer>
        <Typography fontSize={24} fontWeight={500}>
          {isConnectedAndAuthenticated ? 'Checkout' : 'Connect Wallet'}
        </Typography>
        <ModalIconButton onClick={closeModal}>
          <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
        </ModalIconButton>
      </ModalHeaderContainer>
      {!isConnectedAndAuthenticated ? (
        <ConnectWallet />
      ) : (
        <>
          <GeneralFlexBox direction="column" width="100%">
            <CheckoutModalRow padding={theme.spacing(2.5, 3, 0, 3)}>
              <GeneralFlexBox width="100%" direction="column" path={path}>
                <GeneralFlexBox width="100%" direction="column" align="flex-start">
                  <Typography fontSize={16} fontWeight={400}>
                    You&apos;re going to purchase
                  </Typography>
                  <GeneralFlexBox
                    width="100%"
                    padding={theme.spacing(2, 0)}
                    justify="space-between"
                  >
                    <GeneralFlexBox>
                      <CheckoutThumbnail
                        src={getFullMediaUrl(data.thumbnailPathName, data.rawFileUrl)}
                        alt=""
                      />
                      <GeneralFlexBox
                        direction="column"
                        padding={theme.spacing(0, 0, 0, 2)}
                        align="flex-start"
                      >
                        <AssetNameText>{data?.metadata?.name}</AssetNameText>
                        <DescriptonText>{data?.collection}</DescriptonText>
                        <FeeText>
                          Creator fee: {creatorFee}%
                          <ToolTip
                            title={
                              <Typography
                                fontSize={14}
                                lineHeight="20px"
                                letterSpacing="0.25px"
                                fontWeight={500}
                                textAlign="center"
                              >
                                The creators of this collection will receive {creatorFee}% of the
                                sale total from future sales of this item.
                              </Typography>
                            }
                            placement="top"
                            arrow
                          >
                            <StyledInfoOutlinedIcon />
                          </ToolTip>
                        </FeeText>
                      </GeneralFlexBox>
                    </GeneralFlexBox>
                    <GeneralFlexBox direction="column" align="flex-end">
                      <GeneralFlexBox justify="flex-end">
                        <Price
                          price={listingPrice}
                          size="20px"
                          weight={900}
                          logo={20}
                          valid={false}
                          show={true}
                          currency={COIN.Symbol}
                          logocolor="blue"
                        />
                      </GeneralFlexBox>
                      {data.dollar > 0 && (
                        <Typography
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight={500}
                          color={theme.palette.text.secondary}
                          marginTop={theme.spacing(0.5)}
                        >
                          (${formatNumber(data.dollar)})
                        </Typography>
                      )}
                    </GeneralFlexBox>
                  </GeneralFlexBox>
                </GeneralFlexBox>
                <SimpleDivider color={theme.palette.border.separator} />
                <TotalRow direction="column">
                  <GeneralFlexBox width="100%" justify="space-between">
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight="24px"
                      color={theme.palette.text.secondary}
                    >
                      Total:
                    </Typography>
                    <GeneralFlexBox>
                      <GeneralFlexBox justify="flex-end">
                        <Price
                          price={listingPrice}
                          size="20px"
                          weight={900}
                          logo={20}
                          valid={false}
                          show={true}
                          currency={COIN.Symbol}
                          logocolor="blue"
                        />
                      </GeneralFlexBox>
                      {data.dollar > 0 && (
                        <Typography
                          fontSize="14px"
                          lineHeight="20px"
                          fontWeight={500}
                          color={theme.palette.text.secondary}
                          marginLeft={theme.spacing(1)}
                        >
                          (${formatNumber(data.dollar)})
                        </Typography>
                      )}
                    </GeneralFlexBox>
                  </GeneralFlexBox>
                  {!enoughFund && (
                    <Typography
                      marginTop={theme.spacing(3)}
                      fontSize="14px"
                      fontWeight={500}
                      color={theme.palette.text.error}
                    >
                      Insufficient funds in wallet
                    </Typography>
                  )}
                </TotalRow>
              </GeneralFlexBox>
            </CheckoutModalRow>
          </GeneralFlexBox>
          <CheckoutFooterRow>
            {enoughFund ? (
              <StandardButton
                variant="contained"
                width={199}
                height={48}
                onClick={onConfirmPayment}
                disableRipple
              >
                Confirm Payment
              </StandardButton>
            ) : (
              <StandardButton
                variant="contained"
                width={199}
                height={48}
                onClick={onAddFunds}
                disableRipple
              >
                Add Funds
              </StandardButton>
            )}
          </CheckoutFooterRow>
        </>
      )}
    </MainContainer>
  );
};

export default CheckoutPart;
