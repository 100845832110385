import React, { createContext, useEffect } from 'react';
import {
  useAccount,
  useConnect,
  usePublicClient,
  useWalletClient,
  useDisconnect,
  useSwitchChain,
} from 'wagmi';
import { useStateIfMounted } from 'use-state-if-mounted';

import { DEFAULT_NETWORK, SUPPORTED_NETWORKS } from 'constants/index';

export const WalletContext = createContext(null);

export const WalletProvider = ({ children }) => {
  const { address: rawAddress, isConnected, chainId } = useAccount();
  const { connect, connectors: rawConnectors, connectAsync } = useConnect();
  const { disconnect } = useDisconnect();
  const { data: signer } = useWalletClient({ chainId });
  const provider = usePublicClient();
  const { switchChainAsync } = useSwitchChain();

  const [walletModalOpen, setWalletModalOpen] = useStateIfMounted(false);
  const [storedChain, setStoredChain] = useStateIfMounted(
    Number(localStorage.getItem('storedChain')) || DEFAULT_NETWORK,
  );
  const [wrongNetwork, setWrongNetwork] = useStateIfMounted(false);

  const address = rawAddress?.toLowerCase();
  const isInstalled = typeof window.ethereum !== 'undefined';
  const connectors = rawConnectors
    .slice()
    .sort((prev, next) => (prev.name === 'MetaMask' ? -1 : next.name === 'MetaMask' ? 1 : 0));

  const globalChain = isConnected ? chainId : storedChain;

  const updateStoredChain = (targetChain) => {
    localStorage.setItem('storedChain', targetChain);
    setStoredChain(Number(targetChain));
  };

  const switchToNetwork = async (chainId) => {
    return await switchChainAsync({ chainId });
  };

  useEffect(() => {
    if (isConnected && chainId !== storedChain) {
      const supportedChainIds = Object.values(SUPPORTED_NETWORKS);
      if (supportedChainIds.includes(chainId)) {
        localStorage.setItem('storedChain', chainId);
        setWrongNetwork(false);
        window.location.reload();
        return;
      }
      setWrongNetwork(true);
    } else {
      setWrongNetwork(false);
    }
  }, [isConnected, chainId, storedChain]);

  return (
    <WalletContext.Provider
      value={{
        isInstalled,
        isConnected,
        connectors,
        connect,
        connectAsync,
        provider,
        signer,
        chainId,
        address,
        disconnect,
        switchToNetwork,
        walletModalOpen,
        setWalletModalOpen,
        wrongNetwork,
        storedChain,
        globalChain,
        updateStoredChain,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
