import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Button,
  Typography,
  Box,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
  Skeleton,
  Link,
} from '@mui/material';
import {
  ContentCopy as CopyIcon,
  OpenInNew as OpenInNewIcon,
  Circle as CircleIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { useStateIfMounted } from 'use-state-if-mounted';

import { copyToClipboard, shorten } from 'utils/helper';
import { NETWORK_NAME, REDIRECT_LINK_EXPLORER, SUPPORTED_NETWORKS } from 'constants/index';
import { CheckoutModalContext } from 'pages/context';
import useWalletContext from 'hooks/useWalletContext';
import useBalanceContext from 'hooks/useBalanceContext';
import { formatNumber } from 'utils/numbers';

import { ToolTip } from 'components/StyledComponents';
import AddFundModal from '../AddFundModal';
import ContractBox from './ContractsBox';
import { MetamaskIcon } from 'components/ImageComponent';
import { CurrencyIcon } from 'components/Currency';

/********************  Styled Components  ********************/
const Body = styled(Box)(() => ({}));

const CopyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.border.high,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.border.high,
    fontSize: '14px',
    fontWeight: 500,
    padding: '6px 8px',
    letterSpacing: '0.25px',
    marginBottom: '10px !important',
  },
}));

const Address = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.tertiary,
  letterSpacing: '0.15px',
  lineHeight: '24px',
  marginLeft: 8,
}));

const BalanceBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderBottom: 'none',
  borderRadius: 10,
  overflow: 'hidden',
  marginBottom: theme.spacing(2),
}));

const AddFundButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.white,
  fontSize: 16,
  fontWeight: 600,
  padding: theme.spacing(0.8, 2),
  marginTop: theme.spacing(2),
  borderRadius: 8,
  boxShadow: 'none',

  '&:hover': {
    boxShadow: 'none',
  },
}));

const VisibleBox = styled(Box)(({ visibility }) => ({
  marginTop: visibility ? 3 : 0,
  marginLeft: 16,

  '&:hover': {
    cursor: 'pointer',
  },
}));

const Description = styled(Box)(() => ({
  fontSize: 20,
  fontWeight: 600,
  display: 'flex',
  paddingTop: 10,
}));

const AddressBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.border.lighter}`,
  borderRadius: 10,
  overflow: 'hidden',
  marginBottom: theme.spacing(1),
}));

const NetworkBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: '0.15px',
  lineHeight: '24px',
  gap: theme.spacing(1),
}));

const ConnectedBox = styled(Box)(({ theme, active }) => ({
  color: active ? theme.palette.button.active : theme.palette.header,
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: '0.4px',
  lineHeight: '16px',
}));

const Details = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  overflow: 'hidden',
  marginBottom: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.border.separator}`,
}));

const MainBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.filter,
  border: `1px solid ${theme.palette.border.separator}`,
  borderLeft: 0,
  borderRight: 0,
  padding: 14,
}));

/********************  Main Component  ********************/
const MyWallet = ({ setWalletSidebarOpen }) => {
  const theme = useTheme();
  const { isConnected, address, storedChain } = useWalletContext();
  const [copied, setCopied] = useStateIfMounted(null);
  const [copyTimeout, setCopyTimeout] = useStateIfMounted(null);
  const [visibility, setVisibility] = useStateIfMounted(false);
  const [addFundModalOpen, setAddFundModalOpen] = useStateIfMounted(false);
  const [showTooltip, setShowTooltip] = useStateIfMounted(false);
  const [blockExplorer, setBlockExplorer] = useStateIfMounted(false);

  const { totalBalance, balances } = useBalanceContext();
  const below994 = useMediaQuery('(max-width: 994px)');
  const { openModal } = useContext(CheckoutModalContext);

  const copy = async (address, type) => {
    await copyToClipboard(address);
    if (below994) {
      setShowTooltip(!showTooltip);
    }
    setCopied(type);
    if (copyTimeout) {
      clearTimeout(copyTimeout);
      setCopyTimeout(null);
    }
    const timeout = setTimeout(() => {
      setShowTooltip(!showTooltip);
      setCopied(null);
      setCopyTimeout(null);
    }, 1000);
    setCopyTimeout(timeout);
  };

  const handleCloseModal = () => {
    setAddFundModalOpen(false);
  };

  return (
    <Body>
      {isConnected && (
        <MainBox>
          <Details>
            <NetworkBox>
              <CurrencyIcon />
              {NETWORK_NAME[storedChain]}
            </NetworkBox>
            {isConnected && Object.values(SUPPORTED_NETWORKS).includes(storedChain) ? (
              <ConnectedBox active={1}>
                <CircleIcon style={{ width: 8, height: 8, marginRight: 4 }} /> Connected
              </ConnectedBox>
            ) : (
              <ConnectedBox active={0}>
                <CircleIcon style={{ width: 8, height: 8, marginRight: 4 }} /> Not Connected
              </ConnectedBox>
            )}
          </Details>
          <AddressBox>
            <Box style={{ display: 'flex' }}>
              <MetamaskIcon />
              <Address>{shorten(address, 5)}</Address>
            </Box>
            <Box>
              <CopyTooltip
                title={copied === 'address' || below994 ? 'Copied!' : 'Copy'}
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                placement="bottom"
                arrow
              >
                <CopyIcon onClick={() => copy(address, 'address')} style={{ marginRight: 24 }} />
              </CopyTooltip>
              <Link
                style={{ color: theme.palette.text.main }}
                href={`${REDIRECT_LINK_EXPLORER[storedChain]}/address/${address}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <CopyTooltip
                  title={`View on ${NETWORK_NAME[storedChain]}`}
                  open={blockExplorer}
                  onOpen={() => setBlockExplorer(true)}
                  onClose={() => setBlockExplorer(false)}
                  placement="bottom"
                  arrow
                >
                  <OpenInNewIcon />
                </CopyTooltip>
              </Link>
            </Box>
          </AddressBox>

          <BalanceBox>
            <Typography
              fontSize={14}
              fontWeight={400}
              color={
                theme.palette.mode === 'dark'
                  ? theme.palette.text.secondary
                  : theme.palette.text.tertiary
              }
            >
              Total Balance
            </Typography>
            <Description>
              {totalBalance === undefined ? (
                <Skeleton width={200} />
              ) : (
                <>
                  {visibility ? `$${formatNumber(totalBalance)}` : '*****'}
                  <ToolTip title={visibility ? 'Hide balances' : 'Show balances'} placement="top">
                    <VisibleBox
                      onClick={() => setVisibility(!visibility)}
                      visibility={visibility ? 1 : 0}
                    >
                      {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </VisibleBox>
                  </ToolTip>
                </>
              )}
            </Description>
            <AddFundButton
              variant="contained"
              onClick={() => {
                setWalletSidebarOpen(false);
                openModal([], 'add_funds', 'buy');
              }}
              disableRipple
            >
              Add funds
            </AddFundButton>
          </BalanceBox>
          <ContractBox
            visibility={visibility}
            balances={balances}
            setWalletSidebarOpen={setWalletSidebarOpen}
          />
          <AddFundModal open={addFundModalOpen} closeModal={handleCloseModal} address={address} />
        </MainBox>
      )}
    </Body>
  );
};

export default MyWallet;
