import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Modal, styled, useMediaQuery } from '@mui/material';

import useWalletContext from 'hooks/useWalletContext';
import useTokenContext from 'hooks/useTokenContext';
import useBalanceContext from 'hooks/useBalanceContext';

import CheckoutPart from './views/CheckoutPart';
import ProcessingPart from './views/ProcessingPart';
import AddFunds from './views/AddFunds';
import MakeOffer from './views/MakeOffer';
import ConvertCurrency from './views/ConvertCurrency';
import VerifyWalletOwnership from './views/VerifyWalletOwnership';
import TxConfirmation from './views/TxConfirmation';
import WalletConnectors from './views/WalletConnectors';

export const ModalContainer = styled('div')(({ theme, width, path }) => ({
  width: width ?? 550,
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  background: theme.palette.background.secondary,
  borderRadius: 10,

  '&:focus': {
    outline: 'none',
  },
  '@media(min-width: 500px)': {
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  '@media(max-width: 500px)': {
    width: '100%',
    bottom: path === 'buy' ? 'inherit' : 0,
    height: path === 'buy' ? '-webkit-fill-available' : 'auto',
    position: 'fixed',
    borderRadius: '10px 10px 0px 0px',
  },
}));

const ModalCustomWidth = {
  verify_wallet_ownership: {
    above500: 410,
    above905: 423,
  },
  tx_confirmation: {
    above500: 500,
    above905: 500,
  },
};

/********************  Main Component  ********************/
const CheckoutModal = ({ open, closeModal, data, type, path }) => {
  const above905 = useMediaQuery('(min-width: 905px)');

  const { address, disconnect } = useWalletContext();
  const { isAuthenticated } = useTokenContext();
  const { nativeBalance, wrappedBalance, coinLastPrice: lastPrice } = useBalanceContext();

  const [part, setModal] = useStateIfMounted(type);
  const [enableClose, setEnableClose] = useStateIfMounted(true);
  const [secondaryModal, setSecondaryModal] = useStateIfMounted(null);

  const forceModals = ['tx_confirmation'];
  const secondaryModals = ['add_funds', 'convert_currency'];
  const sceenSize = above905 ? 'above905' : 'above500';

  const setCurrentModal = (modal) => {
    if (secondaryModals.includes(modal)) {
      setSecondaryModal(modal);
    } else {
      setModal(modal);
    }
  };

  const closeAllModals = () => {
    setSecondaryModal(null);
    closeModal();
  };

  useEffect(() => {
    setSecondaryModal(null);
    setModal(type);
    setEnableClose(!forceModals.includes(type));
  }, [type]);

  const addFundsModal = (
    <AddFunds
      closeModal={closeAllModals}
      address={address}
      path={path}
      closeSecondaryModal={() => setSecondaryModal(null)}
      prevModal={secondaryModal ? part : null}
    />
  );

  const convertCurrencyModal = (
    <ConvertCurrency
      prevModal={secondaryModal ? part : null}
      onBack={() => setSecondaryModal(null)}
      closeModal={closeAllModals}
    />
  );

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          if (!isAuthenticated && part === 'verify_wallet_ownership') {
            disconnect();
          }
          if (enableClose) {
            closeModal();
          }
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: (theme) => theme.palette.background.overlay,
          },
          zIndex: 2000,
        }}
      >
        <ModalContainer
          width={ModalCustomWidth[part] ? ModalCustomWidth[part][sceenSize] : 490}
          path={path}
        >
          {(() => {
            switch (part) {
              case 'checkout':
                return (
                  <CheckoutPart
                    data={data}
                    closeModal={closeAllModals}
                    onConfirmPayment={() => setCurrentModal('processing')}
                    onAddFunds={() => setCurrentModal('add_funds')}
                    nativeBalance={nativeBalance}
                    path={path}
                    visible={!secondaryModal}
                  />
                );
              case 'processing':
                return (
                  <ProcessingPart
                    data={data}
                    closeModal={closeAllModals}
                    address={address}
                    path={path}
                  />
                );
              case 'make_offer':
                // to check if there is an asset for make offer modal
                if ('contractAddress' in data) {
                  return (
                    <MakeOffer
                      data={data}
                      closeModal={closeAllModals}
                      nativeBalance={nativeBalance}
                      wrappedBalance={wrappedBalance}
                      lastPrice={lastPrice}
                      setModal={setCurrentModal}
                      visible={!secondaryModal}
                    />
                  );
                }
              case 'select_wallet':
                return <WalletConnectors closeModal={closeModal} />;
              case 'verify_wallet_ownership':
                return <VerifyWalletOwnership closeModal={closeModal} callback={data.callback} />;
              case 'add_funds':
                return addFundsModal;
              case 'convert_currency':
                return convertCurrencyModal;
              case 'tx_confirmation':
                return (
                  <TxConfirmation
                    setEnableClose={setEnableClose}
                    closeModal={closeModal}
                    data={data}
                  />
                );
              default:
                return null;
            }
          })()}

          {(() => {
            switch (secondaryModal) {
              case 'add_funds':
                return addFundsModal;
              case 'convert_currency':
                return convertCurrencyModal;
            }
          })()}
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CheckoutModal;
