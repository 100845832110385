import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  Close as CloseIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { useWaitForTransactionReceipt } from 'wagmi';

import { REDIRECT_LINK_EXPLORER } from 'constants/index';
import useWalletContext from 'hooks/useWalletContext';
import { shortenAddress } from 'utils';

import { GeneralFlexBox, PrimaryLink, VerticalFlexBox } from 'components/StyledComponents';
import {
  CheckoutModalRow,
  ModalHeaderContainer,
  ModalIconButton,
} from '../components/styledComponents';

const MainContainer = styled(GeneralFlexBox)(() => ({
  width: '100%',
  flexDirection: 'column',
  // Move footer row to bottom screen when in vertical small screen
  '@media(max-width: 600px)': {
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const MiddleRow = styled(CheckoutModalRow)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4),
}));

const CustomCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  width: 40,
  height: 40,
  color: theme.palette.primary.lighter,
}));

const CustomCancelIcon = styled(CancelIcon)(({ theme }) => ({
  width: 40,
  height: 40,
  color: theme.palette.danger.variant,
}));

/********************  Main Component  ********************/
const TxConfirmation = ({ closeModal, data, setEnableClose }) => {
  const theme = useTheme();
  const { storedChain } = useWalletContext();
  const { hash, content, callbackOnSuccess } = data;
  const { data: result, isLoading, error } = useWaitForTransactionReceipt({ hash });

  useEffect(() => {
    if (!isLoading) {
      setEnableClose(true);

      if (result?.status === 'success') {
        callbackOnSuccess(result);
      }
    }
  }, [isLoading]);

  return (
    <MainContainer direction="column" width="100%">
      <ModalHeaderContainer>
        <Typography fontSize={24} fontWeight={500}>
          Transaction Confirmation
        </Typography>
        {!isLoading && (
          <ModalIconButton onClick={closeModal}>
            <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
          </ModalIconButton>
        )}
      </ModalHeaderContainer>
      <MiddleRow>
        <Box marginBottom={2}>{content}</Box>
        {isLoading ? (
          <VerticalFlexBox>
            <Typography fontWeight={600}>Awaiting Transaction Confirmation</Typography>
            <PrimaryLink
              target="_blank"
              rel="noopener noreferer"
              href={`${REDIRECT_LINK_EXPLORER[storedChain]}/tx/${hash}`}
            >
              {shortenAddress(hash)}
            </PrimaryLink>
            <CircularProgress sx={{ marginTop: 2 }} color="primary" />
          </VerticalFlexBox>
        ) : error ? (
          <VerticalFlexBox>
            <CustomCancelIcon />
            <Typography
              style={{ color: theme.palette.danger.variant }}
              fontSize={13}
              fontWeight={500}
              marginTop={1}
            >
              {error.name}: {error.details}
            </Typography>
          </VerticalFlexBox>
        ) : (
          <CustomCheckCircleIcon />
        )}
      </MiddleRow>
    </MainContainer>
  );
};

export default React.memo(TxConfirmation);
