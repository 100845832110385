import { useCallback } from 'react';

import { usePriceContext } from 'context/PriceContext';
import { formatBigDecimalNumbers, formatNumber, removeCommas } from 'utils/numbers';
import { formatHumanReadableTime } from 'utils/helper';

const useFormatOfferResponse = () => {
  const { coinPrice } = usePriceContext();

  const formatOfferResponse = useCallback(
    async (offerResponse) => {
      if (!offerResponse?.length) {
        return;
      }
      const offerNfts = offerResponse.map(async (offer) => {
        const { maker } = offer ?? {};
        const price = formatBigDecimalNumbers(Number(offer.OrderAmountEth));
        const dollarPrice = formatNumber(Number(removeCommas(price)) * coinPrice);

        return {
          ...offer,
          price,
          dollarPrice,
          collection: offer?.contract?.name,
          collectionFloorPrice: offer?.contract?.floorPrice,
          tooltipDate: formatHumanReadableTime(Number(offer.MatchBeforeTimestamp) * 1000),
          pageLink: `/asset/${offer.NftContract}/${offer.NftToken}`,
          name: offer?.token?.name,
          buyer: maker?.username || maker?.ensDomain || maker?.address?.slice(0, 6),
        };
      });

      const formattedOffers = await Promise.all(offerNfts);
      return formattedOffers;
    },
    [coinPrice],
  );

  return formatOfferResponse;
};

export default useFormatOfferResponse;
