import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Typography, useMediaQuery, Box, styled, useTheme } from '@mui/material';

import { LISTING_DURATIONS } from 'constants/index';

import { CustomExpandMore, GeneralFlexBox } from 'components/StyledComponents';
import DayDesktopView from './DayDesktopView';
import DayMobileView from './DayMobileView';
import DateTimePicker from '../DateTimePicker';

const StyledBox = styled(Box)(({ theme }) => ({
  width: '180px',
  marginRight: theme.spacing(1),
  height: 48,
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: 4,
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 8,
  fontWeight: 500,
  letterSpacing: '0.1px',
  lineHeight: '20px',
  cursor: 'pointer ',
  background: theme.palette.background.filter,
  color: theme.palette.text.main,
  paddingLeft: theme.spacing(2),

  '&:hover': {
    background: theme.palette.background.dropdown.hovered,
  },
}));

const OfferExpiration = ({ expiredDate, setExpiredDate, dateWithExactTime, setExactTime }) => {
  const theme = useTheme();
  const below500 = useMediaQuery('(max-width: 500px)');

  const [open, setOpen] = useStateIfMounted(false);

  const renderMobileValue = (selected) => {
    const duration = LISTING_DURATIONS.find((item) => item.value === selected);
    return duration ? duration.key : 'Custom Date';
  };

  return (
    <>
      <Typography marginBottom={theme.spacing(1)} fontWeight={500} fontSize={14}>
        Expiration
      </Typography>
      <GeneralFlexBox width="100%">
        {!below500 ? (
          <DayDesktopView expiredDate={expiredDate} setExpiredDate={setExpiredDate} />
        ) : (
          <>
            <StyledBox onClick={() => setOpen(true)}>
              <Typography fontSize={14} fontWeight={500}>
                {renderMobileValue(expiredDate)}
              </Typography>
              <CustomExpandMore />
            </StyledBox>
            <DayMobileView
              open={open}
              setOpen={setOpen}
              expiredDate={expiredDate}
              setExpiredDate={setExpiredDate}
            />
          </>
        )}
        <DateTimePicker
          expiredDate={expiredDate}
          dateWithExactTime={dateWithExactTime}
          setExpiredDate={setExpiredDate}
          setExactTime={setExactTime}
        />
      </GeneralFlexBox>
    </>
  );
};

export default OfferExpiration;
